import React, { useState } from 'react';
import './MedilistForm.css'; // Assume we'll create this CSS file for custom styles

const MedilistForm = ({ personaName, conversationId, onFormDataChange }) => {
    // Load initial state from localStorage or use default values
    const [patientInfo, setPatientInfo] = useState(() => {
        const saved = localStorage.getItem('medilistPatientInfo');
        return saved ? JSON.parse(saved) : {
            patientName: '',
            dob: '',
            address: '',
            allergies: ''
        };
    });
    
    const [medications, setMedications] = useState(() => {
        const saved = localStorage.getItem('medilistMedications');
        return saved ? JSON.parse(saved) : [{ medication: '', dose: '', comments: '' }];
    });
    
    const [changedMedications, setChangedMedications] = useState(() => {
        const saved = localStorage.getItem('medilistChangedMedications');
        return saved ? JSON.parse(saved) : [{ medication: '', comments: '' }];
    });

    const [feedback, setFeedback] = useState('');
  
    // Update parent component when form data changes
    React.useEffect(() => {
        if (onFormDataChange) {
            const formData = {
                personaName,
                conversationId,
                patientName: patientInfo.patientName,
                dob: patientInfo.dob || '',
                address: patientInfo.address || '',
                allergies: patientInfo.allergies || '',
                medications: medications.map(med => med.medication || ''),
                doses: medications.map(med => med.dose || ''),
                comments: medications.map(med => med.comments || ''),
                changedMedications: changedMedications.map(med => med.medication || ''),
                changedComments: changedMedications.map(med => med.comments || '')
            };
            onFormDataChange(formData);
        }
    }, [personaName, conversationId, patientInfo, medications, changedMedications, onFormDataChange]);

  const handlePatientInfoChange = (e) => {
    setPatientInfo({ ...patientInfo, [e.target.name]: e.target.value });
  };

  const handleMedicationChange = (index, field, value) => {
    const newMedications = [...medications];
    newMedications[index][field] = value;
    setMedications(newMedications);
  };

  const handleChangedMedicationChange = (index, field, value) => {
    const newChangedMedications = [...changedMedications];
    newChangedMedications[index][field] = value;
    setChangedMedications(newChangedMedications);
  };

  const addMedicationRow = () => {
    setMedications([...medications, { medication: '', dose: '', comments: '' }]);
  };

  const addChangedMedicationRow = () => {
    setChangedMedications([...changedMedications, { medication: '', comments: '' }]);
  };

  // Save to localStorage whenever state changes
  React.useEffect(() => {
    localStorage.setItem('medilistPatientInfo', JSON.stringify(patientInfo));
    localStorage.setItem('medilistMedications', JSON.stringify(medications));
    localStorage.setItem('medilistChangedMedications', JSON.stringify(changedMedications));
  }, [patientInfo, medications, changedMedications]);

  const clearStoredData = () => {
    localStorage.removeItem('medilistPatientInfo');
    localStorage.removeItem('medilistMedications');
    localStorage.removeItem('medilistChangedMedications');
  };

  // Function to collect final data before submission
  const collectFinalData = () => {
    console.log(`[${new Date().toISOString()}] 📋 Collecting final medilist form data`);
    if (onFormDataChange) {
      const formData = {
        personaName,
        conversationId,
        patientName: patientInfo.patientName,
        dob: patientInfo.dob || '',
        address: patientInfo.address || '',
        allergies: patientInfo.allergies || '',
        medications: medications.map(med => med.medication || ''),
        doses: medications.map(med => med.dose || ''),
        comments: medications.map(med => med.comments || ''),
        changedMedications: changedMedications.map(med => med.medication || ''),
        changedComments: changedMedications.map(med => med.comments || '')
      };
      onFormDataChange(formData);
      console.log(`[${new Date().toISOString()}] ✅ Final medilist data collected successfully`);
    }
  };

  // Export method to clear data (will be called by parent when conversation ends)
  React.useEffect(() => {
    // Expose methods to parent through global ref
    if (!window.medilistFormRef) {
      window.medilistFormRef = {};
    }
    window.medilistFormRef.clearData = clearStoredData;
    window.medilistFormRef.collectFinalData = collectFinalData;
    
    return () => {
      if (window.medilistFormRef) {
        delete window.medilistFormRef.clearData;
        delete window.medilistFormRef.collectFinalData;
      }
    };
  }, [patientInfo, medications, changedMedications, onFormDataChange, personaName, conversationId]);

return (
    <div className="medilist-container">
        <div id="medicationForm" className="medilist-form">
            <div className="form-section">
                <p className="form-note">
                    Note: Students must include all aspects of medication use
                    (name, strength, form, dose, route, frequency). Additional administration advice (swallow whole,
                    with food) is not required unless specified.
                </p>

                {/* Patient Info Fields */}
                {Object.entries(patientInfo).map(([key, value]) => (
                    <div className="form-group" key={key}>
                        <label htmlFor={key}>{key === 'dob' ? 'Date of Birth' : key.charAt(0).toUpperCase() + key.slice(1).replace(/([A-Z])/g, ' $1')}:</label>
                        <input
                            type={key === 'dob' ? 'date' : 'text'}
                            id={key}
                            name={key}
                            value={value}
                            onChange={handlePatientInfoChange}
                            required={key === 'patientName'}
                            className="form-input"
                        />
                    </div>
                ))}
            </div>

            <div className="form-section">
                <h3 className="section-title">Current medications:</h3>
                <table className="medication-table">
                    <thead>
                        <tr>
                            <th>Medication Name, Strength and Form</th>
                            <th>Dose, route and frequency</th>
                            <th>Other comments</th>
                        </tr>
                    </thead>
                    <tbody>
                        {medications.map((med, index) => (
                            <tr key={index}>
                                {Object.keys(med).map((field) => (
                                    <td key={field}>
                                        <textarea
                                            name={`${field}[]`}
                                            value={med[field]}
                                            onChange={(e) => handleMedicationChange(index, field, e.target.value)}
                                            required={false}
                                            className="form-textarea"
                                        />
                                    </td>
                                ))}
                            </tr>
                        ))}
                    </tbody>
                </table>
                <button type="button" className="btn btn-add" onClick={addMedicationRow}>
                    + Add Medication
                </button>
            </div>

            <div className="form-section">
                <h3 className="section-title">Recently changed/ceased medications:</h3>
                <table className="medication-table">
                    <thead>
                        <tr>
                            <th>Medication Name, Strength and Form</th>
                            <th>Comment(s)</th>
                        </tr>
                    </thead>
                    <tbody>
                        {changedMedications.map((med, index) => (
                            <tr key={index}>
                                {Object.keys(med).map((field) => (
                                    <td key={field}>
                                        <textarea
                                            name={`changed${field.charAt(0).toUpperCase() + field.slice(1)}[]`}
                                            value={med[field]}
                                            onChange={(e) => handleChangedMedicationChange(index, field, e.target.value)}
                                            required={false}
                                            className="form-textarea"
                                        />
                                    </td>
                                ))}
                            </tr>
                        ))}
                    </tbody>
                </table>
                <button type="button" className="btn btn-add" onClick={addChangedMedicationRow}>
                    + Add Changed/Ceased Medication
                </button>
            </div>

            {/* Removed submit button */}
            {/* Added note about form submission */}
            <p className="info-text">This form will be automatically submitted when you end the conversation.</p>
        </div>
        {feedback && <div className="feedback" dangerouslySetInnerHTML={{ __html: feedback }}></div>}
    </div>
);
};

export default MedilistForm;