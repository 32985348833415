import React, { useState, useEffect } from 'react';
import './MentorReport.css'; // Make sure this file contains the provided CSS

const MentorReport = ({ conversationId, personaType, personaName, user, userName, conversationHistory, conversation_structure_text, medilistData, showMedilist }) => {
  const [isGenerating, setIsGenerating] = useState(false);
  const [error, setError] = useState('');
  const [progress, setProgress] = useState(0);
  const [progressStage, setProgressStage] = useState('');
  const [elapsed, setElapsed] = useState(0);
  const [startTime, setStartTime] = useState(null);

  // Timer to track elapsed time during generation
  useEffect(() => {
    let timer;
    if (isGenerating && startTime) {
      timer = setInterval(() => {
        const newElapsed = Math.floor((Date.now() - startTime) / 1000);
        setElapsed(newElapsed);
      }, 1000);
    } else {
      setElapsed(0);
    }
    return () => clearInterval(timer);
  }, [isGenerating, startTime]);

  // Format elapsed time nicely
  const formatTime = (seconds) => {
    const mins = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${mins}:${secs < 10 ? '0' : ''}${secs}`;
  };

  // Add additional context to progress stages based on elapsed time
  const getContextualMessage = () => {
    if (elapsed < 5) return '';
    
    if (progressStage.includes('Analyzing')) {
      return '(AI is analyzing conversation patterns and communication style)';
    } else if (progressStage.includes('Creating')) {
      return '(AI is formulating detailed performance feedback)';
    } else if (progressStage.includes('Formatting')) {
      return '(Preparing professional PDF layout)';
    }
    return '';
  };

  const generateReport = async () => {
    setIsGenerating(true);
    setError('');
    setProgress(5);
    setProgressStage('Initializing report generation');
    setStartTime(Date.now());
    console.log(`[${new Date().toISOString()}] 🚀 Starting ATLAS report generation...`);

    try {
      await new Promise(resolve => setTimeout(resolve, 500)); // Brief delay for UI feedback
      
      setProgress(10);
      setProgressStage('Preparing data for analysis');
      console.log(`[${new Date().toISOString()}] 📦 Preparing analysis data...`);
      
      // Create request payload
      const requestPayload = { 
        conversation_id: conversationId,
        persona_type: personaType,
        persona_name: personaName,
        conversation_history: conversationHistory,
        conversation_structure_text: conversation_structure_text,
        format: 'pdf_only' 
      };
      
      // Add medilist data if available
      if (showMedilist && medilistData) {
        console.log(`[${new Date().toISOString()}] 📋 Including medilist data in report generation`);
        requestPayload.medilist_data = medilistData;
      }

      setProgress(20);
      setProgressStage('Analyzing conversation data');
      console.log(`[${new Date().toISOString()}] 📊 Analyzing conversation data...`);
      const response = await fetch('https://www.nump0.com/persona/generate_mentor_report_react', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        credentials: 'include',
        body: JSON.stringify(requestPayload),
      });

      console.log(`[${new Date().toISOString()}] 📬 Response status:`, response.status);

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || `HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      console.log(`[${new Date().toISOString()}] 📄 Report data received...`);
      
      setProgress(60);
      setProgressStage('Creating professional report');
      console.log(`[${new Date().toISOString()}] 🎨 Formatting professional PDF report...`);
      
      // Generate PDF
      const pdfRequestPayload = {
        mentor_content: data.mentor_content,
        body_language_content: data.body_language_content,
        conversation_id: conversationId,
        user: user,
        user_name: userName,
        conversation_history: conversationHistory,
        format: 'pdf_only'
      };
      
      // Add medilist analysis to PDF if available
      if (data.medilist_content) {
        pdfRequestPayload.medilist_content = data.medilist_content;
        console.log(`[${new Date().toISOString()}] 💊 Including medication list analysis in report`);
      }
      
      setProgress(75);
      setProgressStage('Generating PDF document');
      console.log(`[${new Date().toISOString()}] 📑 Converting to PDF format...`);
      const pdfResponse = await fetch('https://www.nump0.com/persona/download_mentor_report_react', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        credentials: 'include',
        body: JSON.stringify(pdfRequestPayload),
      });

      if (!pdfResponse.ok) {
        const pdfErrorData = await pdfResponse.json();
        throw new Error(pdfErrorData.error || 'Failed to generate PDF');
      }

      const pdfData = await pdfResponse.json();
      if (pdfData.token) {
        setProgress(90);
        setProgressStage('Preparing file for download');
        console.log(`[${new Date().toISOString()}] ✅ PDF report generated successfully`);
        
        // Automatically trigger download
        const downloadUrl = `https://www.nump0.com/persona/get_mentor_report_react/${pdfData.token}`;
        
        setProgress(95);
        setProgressStage('Downloading ATLAS report');
        console.log(`[${new Date().toISOString()}] 📥 Initiating download...`);
        
        const link = document.createElement('a');
        link.href = downloadUrl;
        link.download = 'atlas_report.pdf';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        
        setProgress(100);
        setProgressStage('Report downloaded successfully');
        console.log(`[${new Date().toISOString()}] 🎉 Report downloaded successfully in ${formatTime(elapsed)}`);
        
        setTimeout(() => {
          setProgress(0);
          setProgressStage('');
          setIsGenerating(false);
          setStartTime(null);
        }, 3000);
      } else {
        throw new Error('No token received for PDF download');
      }

    } catch (error) {
      console.error(`[${new Date().toISOString()}] ❌ Error in generateReport:`, error);
      setError(error.message || 'An unexpected error occurred');
      setProgressStage('Error generating report');
      setIsGenerating(false);
      setStartTime(null);
    }
  };

  return (
    <div className="mentor-report">
      <button 
        onClick={generateReport} 
        disabled={isGenerating}
        className={`generate-button ${isGenerating ? 'pulsing' : ''}`}
      >
        {isGenerating ? 'Generating Report...' : 'Press to Generate and Download ATLAS Report'}
      </button>

      {isGenerating && (
        <div className="progress-container">
          <div className="progress-header">
            <span className="progress-title">ATLAS Report Generation</span>
            <span className="progress-time">{formatTime(elapsed)}</span>
          </div>
          <div className="progress-bar">
            <div 
              className="progress-fill" 
              style={{ width: `${progress}%` }}
            ></div>
          </div>
          <div className="progress-stage">
            <div className="progress-stage-main">
              <span>{progressStage}</span>
              <span className="timestamp">{new Date().toLocaleTimeString()}</span>
            </div>
            {getContextualMessage() && (
              <div className="progress-context">{getContextualMessage()}</div>
            )}
          </div>
        </div>
      )}

      {error && (
        <div className="error-message">
          {error}
        </div>
      )}
    </div>
  );
};

export default MentorReport;
