import React, { useRef, useEffect, useState } from 'react';

const WebcamCapture = ({ isRecording, conversationId }) => {
  const videoRef = useRef(null);
  const canvasRef = useRef(null);
  const streamRef = useRef(null);
  const captureIntervalRef = useRef(null);
  const initialCaptureDoneRef = useRef(false);
  const [debugInfo, setDebugInfo] = useState('');

  useEffect(() => {
    if (isRecording) {
      startWebcam();
    } else {
      stopWebcam();
    }
    return () => {
      stopWebcam();
    };
  }, [isRecording]);

  const startWebcam = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ video: true });
      streamRef.current = stream;
      if (videoRef.current) {
        videoRef.current.srcObject = stream;
        videoRef.current.onloadedmetadata = () => {
          setDebugInfo('Video metadata loaded');
          videoRef.current.play();
          
          // Reset the initial capture flag when starting new recording
          initialCaptureDoneRef.current = false;
          
          // Wait a short moment for the video to stabilize, then capture first frame
          setTimeout(() => {
            captureFrame();
            startFrameCapture();
          }, 500);
        };
      } else {
        setDebugInfo('Video reference is not available');
      }
    } catch (error) {
      setDebugInfo(`Error accessing webcam: ${error.message}`);
    }
  };

  const stopWebcam = () => {
    if (streamRef.current) {
      streamRef.current.getTracks().forEach(track => track.stop());
    }
    if (captureIntervalRef.current) {
      clearInterval(captureIntervalRef.current);
    }
    setDebugInfo('Webcam stopped');
  };

  const startFrameCapture = () => {
    // Start the regular interval after the first frame
    captureIntervalRef.current = setInterval(() => {
      captureFrame();
    }, 3000); // Capture a frame every 3 seconds
  };

  const captureFrame = () => {
    if (videoRef.current && canvasRef.current) {
      const ctx = canvasRef.current.getContext('2d');
      canvasRef.current.width = videoRef.current.videoWidth;
      canvasRef.current.height = videoRef.current.videoHeight;
      ctx.drawImage(videoRef.current, 0, 0, canvasRef.current.width, canvasRef.current.height);
      
      const imageData = ctx.getImageData(0, 0, canvasRef.current.width, canvasRef.current.height);
      const isBlack = imageData.data.every((value, index) => index % 4 !== 3 && value === 0);
      
      if (initialCaptureDoneRef.current) {
        setDebugInfo(`Capturing regular frame. Is black? ${isBlack}. Dimensions: ${canvasRef.current.width}x${canvasRef.current.height}`);
      } else {
        setDebugInfo(`Capturing FIRST frame. Is black? ${isBlack}. Dimensions: ${canvasRef.current.width}x${canvasRef.current.height}`);
        initialCaptureDoneRef.current = true;
      }

      if (!isBlack) {
        const base64data = canvasRef.current.toDataURL('image/jpeg').split(',')[1];
        sendFrameToServer(base64data);
      } else {
        setDebugInfo('Frame is black, not sending to server');
      }
    } else {
      setDebugInfo('Video or canvas reference is not available');
    }
  };

  const sendFrameToServer = async (base64data) => {
    const formData = new FormData();
    formData.append('frame', base64data);
    formData.append('conversation_id', conversationId);
    try {
      const response = await fetch('https://nump0.com/persona/capture_frame_react', {
        method: 'POST',
        body: formData,
      });
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const responseData = await response.json();
      setDebugInfo(`Server response: ${JSON.stringify(responseData)}`);
    } catch (error) {
      setDebugInfo(`Error sending frame: ${error.message}`);
    }
  };
  
  return (
      <div style={{ display: isRecording ? 'block' : 'none', position: 'relative' }}>
        <video
          ref={videoRef}
          style={{
            width: '40px',
            height: '30px',
            borderRadius: '10px',
          }}
          autoPlay
          playsInline
          muted
        />
        <canvas
          ref={canvasRef}
          style={{
            display: 'none',
            border: '1px solid white',
            borderRadius: '10px',
          }}
        />
        <div style={{
          position: 'absolute',
          top: '100%',
          left: 0,
          backgroundColor: 'rgba(0,0,0,0.7)',
          color: 'white',
          padding: '5px',
          fontSize: '12px',
          whiteSpace: 'pre-wrap',
          maxWidth: '320px',
          wordBreak: 'break-word',
          borderRadius: '10px',
        }}>
          {/* Debug Info: {debugInfo} */}
        </div>
      </div>
    );
}

export default WebcamCapture;